<template>
	<div class="page-error" v-bind="getAllData">
		<div v-if="locale">
			<div class="page-error-icon">
				<div><img src="/img/icons/apple-touch-icon-60x60.png" /></div>
			</div>
			<div class="page-error-message">
				<div class="page-error-message-label">
					<h3>{{ locale.error.title }}</h3>
				</div>
				<div class="page-error-message-query">
					<span>{{ message }}</span>
				</div>
			</div>
			<div class="page-error-actions">
				<a href="/" class="primary-button">{{ locale.error.reload_btn }}</a>
			</div>
		</div>
	</div>
</template>

<script>
import store from '../store/index.js';
export default {
	data() {
		return {
			message: this.$route.query.message,
			code: this.$route.query.code,
			locale: null,
		};
	},
	methods: {
		async getdataLang() {
			this.locale = await store.getters['localeLang'];
		},
	},
	computed: {
		getAllData: async function () {
			this.getdataLang();
		},
	},
};
</script>

<style lang="scss">
@import '@/assets/styles/vars.scss';
.page-error {
	padding: 20px;
	text-align: center;
	&-message {
		margin: 10px 0;
		&-label {
			color: $red;
		}
		&-query {
			margin-top: 5px;
		}
	}
	&-icon {
		font-size: 150%;
		color: $red;
		filter: grayscale(100%);
	}
	&-actions {
		margin: $mpadding;
		a {
			display: inline-block;
		}
	}
}
.header .inner-logo {
	pointer-events: none;
}
</style>
